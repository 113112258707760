import React from 'react'
import styled from 'styled-components'
import MapGL, { Marker } from 'react-map-gl'

import Layout from '../components/layout'
import MapPin from '../components/MapPin'
import 'mapbox-gl/dist/mapbox-gl.css'

import CITIES from '../data/cities.json'

const Wrapper = styled.div`
  font-family: 'HKGroteskRegular';
  color: white;
  display: grid;
  height: 100vh;
  @media (min-width: 600px) {
    /* padding: 10% 5%; */
    font-size: 100%;
    grid-template-columns: 0.45fr 0.55fr;
  }

  a {
    color: black;
    font-weight: 800;
    text-decoration: none;
    padding-bottom: 32x;
    &:visited {
      color: black;
    }
  }
`
const Left = styled.div`
  display: grid;
  align-items: center;
  justify-self: center;
`
const NameBox = styled.div`
  z-index: 5;
  color: black;
  background: white;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  @media (min-width: 600px) {
    text-align: left;
    h1,
    h2,
    h3 {
      padding: 2% 5%;
    }
  }
  h1 {
    padding: 2% 8% 0% 8%;
    font-family: 'HKGroteskBold';
    font-weight: 900;
    font-size: 1.5em;
  }

  h2 {
    padding: 2% 10%;
    color: black;
    font-family: 'HKGroteskRegular';
    font-weight: 500;
  }
  h3 {
    padding: 2% 8%;
    font-size: 1em;
    line-height: 165%;
    font-weight: 500;
  }
`
const MapWrapper = styled.div`
  z-index: 1;
  opacity: 0.95;
  background: #1a1a1a;

  position: absolute;
  height: 100vh;
  width: 100vw;
`
const BreakLine = styled.div`
  border-bottom: 1px solid #cccccc;
`
const ContactButton = styled.button`
  margin: 6% 8%;
  border-radius: 30px;
  background: black;
  cursor: pointer;
  color: white;
  padding: 10px 40px;
  font-size: 0.9em;
  font-weight: 600;
  margin-top: 20px;
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: white;
    color: black;
  }
`

class Map extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewport: {
        width: '100%',
        height: '100%',
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 1,
        continuousWorld: true,
        noWrap: false,
        maxBounds: 1,
        attributionControl: false,
        mapboxApiAccessToken:
          'pk.eyJ1IjoiY2ZvdG8iLCJhIjoiY2o2NWtoeWV1MjBzeDJxanh5YjN1cGZoZiJ9.Hg099tEjck2yvL_qa4wPdA',
      },
      popupInfo: null,
    }
  }

  _renderCityMarker = (city, index) => {
    return (
      <Marker
        key={`marker-${index}`}
        longitude={city.longitude}
        latitude={city.latitude}
      >
        <MapPin size={20} onClick={() => this.setState({ popupInfo: city })} />
      </Marker>
    )
  }

  render() {
    const { viewport } = this.state

    return (
      <MapGL
        {...viewport}
        onViewportChange={viewport => this.setState({ viewport })}
        mapStyle="mapbox://styles/cfoto/cje1pa2g26xbf2sqoyfqpfesq"
      >
        {CITIES.map(this._renderCityMarker)}
      </MapGL>
    )
  }
}

const IndexPage = () => (
  <Layout>
    <Wrapper>
      <Left>
        <NameBox>
          <h1>Chase Olivieri</h1>
          <h2>Product-focused global developer.</h2>
          <BreakLine />
          <h3>
            International man of mystery. Software and travel afficionado.
            <br /> Founder of{' '}
            <a
              href="https://usealtitude.com?ref=cpo.is"
              rel="noopener noreferrer"
              target="_blank"
            >
              Altitude
            </a>{' '}
            and{' '}
            <a
              href="https://listolabs.com?ref=cpo.is"
              rel="noopener noreferrer"
              target="_blank"
            >
              Listo Labs
            </a>
            .
          </h3>
          <BreakLine />
          <a href="mailto:hi@cpo.is" rel="noopener noreferrer" target="_blank">
            <ContactButton>Say Hi</ContactButton>
          </a>
        </NameBox>
      </Left>
      <MapWrapper>
        <Map />
      </MapWrapper>
    </Wrapper>
  </Layout>
)

export default IndexPage
