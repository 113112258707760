import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import './layout.css'

const Wrapper = styled.div``

const Layout = ({ children }) => (
  <Wrapper>
    <Helmet
      title="Chase Olivieri: Maker + Adventurer"
      meta={[
        {
          name: 'description',
          content:
            'Chase Olivieri builds products on the interet using modern tools. Clients include Altitude, Listo Labs, Patagonia, Outdoor Industry Association, and more.',
        },
        {
          name: 'keywords',
          content:
            'Chase Olivieri, digital travel, remote product studio, product development',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    {children}
  </Wrapper>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
